/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// exports.onRouteUpdate = ({ location, prevLocation }) => {}

import * as React from "react"

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "gatsby-route-change",
    })
  }
}
